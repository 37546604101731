/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
//@import 'node_modules/primeflex/primeflex.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;900&display=swap');

body, html {
  margin: 0;
  font-family: 'Montserrat';
  // font-family: 'Poppins', "Lato", sans-serif;
  font-size: 14px;
  //background-color: #EDF1F5;
  scroll-behavior: smooth;
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}


/*
 Temporary Styling for auth screens.
 No framework is added, you can add framework of your choice.
 */
.login-page, .register-page {

  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;

  .card {
    width: 400px;
    //height: 400px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px ;
    padding-bottom: 30px;

    .card-header {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 10px;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        .form-group {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
            flex-direction: column;

          small{
            display: block;
          }
        }

        label {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;
          display: block;
        }

        input[type="text"],input[type="email"], input[type="password"] {
          //width: 100%;
          //height: 40px;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 10px;
          font-size: 16px;
          outline: none;
        }

        .form-submit {
          width: 100%;
          margin-top: 20px;
        }

        button {
          width: 100%;
          height: 40px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;

          &:hover {
            background-color: #0069d9;
          }
        }

        .invalid-feedback{
          color: red;
          //font-size: 12px;
          margin-top: 5px;
        }
      }

    }

  }

}

$develop-color: #00eaa4;
$conceptualization-color: #F9E186;

// Colores primarios
$color-primario: #F6D24B;
$color-primario-hover: #675820;
$color-primario-claro: #E0BF44;

// Colores secundarios
$color-secundario: #FBEAAC;

// Color terciario
$color-terciario: #5B2D00;

// Color de texto
$color-texto: #1E2225;

// Variables

$headerTextColor: #171A1D;
$headerBackground: #FEFBED;
$headerBottomBorder: #E3E3E3;
$rowTextColor: #AF9535;

// Definir estilos globales
body {
  // background-color: $color-terciario;
  background-color: #ffffff;
  color: $color-texto;
}

.button-primary {
  background-color: $color-primario;
  color: $color-texto;

  &:hover {
    background-color: $color-primario-hover;
  }
}

.button-secondary {
  background-color: $color-secundario;
  color: $color-texto;
}
